import axios from 'axios'
import { showFailToast } from 'vant';
import { useUserStore } from '@/stores/user';
import { router } from '@/router';
import { BASE_URL } from '@/config';

const instance = axios.create({
  baseURL: BASE_URL
})

export const POST = (url, data, config) => {
  return instance.post(url, data, config)
}

export const GET = (url, params) => {
  return instance.get(url, { params })
}
export const downLoad = (url) =>{
    return instance.get(url,{
      responseType: 'blob'
    })
}
// 添加请求拦截器
instance.interceptors.request.use((config) => {
  const userStore = useUserStore()
  const langMap = {
    'en_US': 'en',
    'fr_FR': 'fr_FR',
    'es_ES': 'es_ES',
    'it_IT': 'it_IT',
    'vi': 'vt_VT',
  };
  config.headers['language'] = langMap[window.localStorage.getItem('lang')] || 'en'
  if (userStore.token) {
    config.headers.token = userStore.token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use((response) => {
  if (response.data.code === 401) {
    router.push('/login')
    return Promise.reject(response.data)
  }
  return response.data
}, function (error) {
  showFailToast(error.response.data.message)
  return Promise.reject(error)
})
